<template>
    <div>
        <div class="bg-light vh-100 vw-100 position-fixed d-flex justify-content-center flex-column">
            <div v-if="dialog != false" style="z-index:100" class="position-fixed vw-100">
                <transition name="modal">
                    <div style="z-index:10000;" class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div v-if="dialog.errors != undefined">
                                    <h3 class="text-danger">Attenzione!</h3>
                                    <div v-for="(error,index) in dialog.errors" :key=index>
                                        <p class="text-danger">{{error}}</p>
                                    </div>
                                </div>
                                <div v-if="dialog.infos != undefined">
                                    <div v-for="(info,index) in dialog.infos" :key=index>
                                        <p>{{info}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" @click="dialog = false">Close</button>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <RecoveryForm :recovery_key="recovery_key" style="overflow-y:auto; overflow-x:hidden;"/>
        </div>
    </div>
</template>

<script>
import RecoveryForm from '../../components/backoffice/RecoveryForm.vue';

export default {
    name: 'Recovery',
    components: {
        RecoveryForm,
    },
    data: function(){
        return {
            dialog: false,
            recovery_key: this.$route.params.recovery_key,
        }
    },
    methods:{
        setDialog(value,data){
            this.dialog = {}
            this.dialog[value] = this.$root.responseTranslator(value,data);
        },
    }
}
</script>
data;