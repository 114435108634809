<template>
    <div class="container">
        <div class="row border rounded bg-white">
            <div class="bg-info col-12 rounded-top text-center">
                <h2 class="m-0 p-2 text-light w-100"><strong>Recupero Password</strong></h2>
            </div>
             <div class="col-12 d-flex justify-content-center">
                <img style="height:300px; width:300px;" class="p-5" src="../../assets/img/logo-cropped-light.png">
            </div>
            <form class="w-100" @submit.prevent="recoverySubmit()">
                <div class="col-12 pt-2 pl-5 pr-5">
                    <input type="text" hidden v-model="recovery_key">
                    <strong>Username</strong>
                </div>
                <div class="col-12 pl-5 pr-5">
                    <input class="w-100" v-model="username" type="text" placeholder="username">
                </div>
                <div class="col-12 pl-5 pt-2 pr-5">
                    <strong>New Password</strong>
                </div>
                <div class="col-12 pl-5 pr-5">
                    <input class="w-100" v-model="password" type="password" placeholder="password">
                </div>
                <div class="col-12 pl-5 pt-2 pr-5">
                    <strong>Confirm Password</strong>
                </div>
                <div class="col-12 pl-5 pr-5">
                    <input class="w-100" v-model="confirm_password" type="password" placeholder="new password">
                </div>
                <div class="col-12 pl-5 pt-3 pb-3 pr-5">
                    <input class="w-100 btn btn-info" type="submit" value="Recupera">
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecoveryForm',
    data: function (){
        return {
            username: '',
            password: '',
            confirm_password: '',
            csrf_token: '',
        }
    },
    props: {
        recovery_key:{
            type: String,
            required: true
        }
    },
    mounted(){
        this.getToken();
    },
    methods: {
        getToken: async function (){
            const res = await fetch(`${this.$api_url}/backend/token_gen.php`,{
                method: "POST",
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                this.csrf_token = json.csrf_token;
            }
        },
        recoverySubmit: async function (){
            var formData = new FormData();
            formData.append('csrf_token',this.csrf_token);
            formData.append('username',this.username);
            formData.append('password',this.password);
            formData.append('confirm_password',this.confirm_password);
            formData.append('recovery_key',this.recovery_key);
            const res = await fetch(`${this.$api_url}/backend/recovery.php`,{
                method: "POST",
                body: formData,
                credentials: 'include'
            });
            if(res.status != 200){
                this.$parent.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                json.redirect != undefined ? this.$router.replace(json.redirect) : 0;
                json.errors != undefined ? this.$parent.setDialog('errors',json[0]) : 0;
            }
        }
    }
}
</script>